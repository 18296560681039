var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import style from "./Footer.module.css";
import Footer_Main_icon from "../../assets/img/Footer_Main_icon.svg";
import Footer_Events_icon from "../../assets/img/Footer_Events_icon.svg";
// import Footer_Blog_icon from "../../assets/img/Footer_Blog_icon.svg";
import Footer_Person_icon from "../../assets/img/Footer_Person_icon.svg";
import Footer_Forum_icon from "../../assets/img/Footer_Forum_icon.svg";
import Footer_Company_icon from "../../assets/img/Footer_Company_icon.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { checkWhoIsWho } from "../../utils/utill_methods/checkWhoIsWho";
import { NEW_URL } from "../../api/API";
import { setAuthModalOpenStatus } from "../../redux/modalReducer";
export function Footer() {
    var userRole = useSelector(function (state) { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userAuthInfo) === null || _b === void 0 ? void 0 : _b.role; });
    var isSuperUser = useSelector(function (state) { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userAuthInfo) === null || _b === void 0 ? void 0 : _b.is_superuser; });
    var userAvatar = useSelector(function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userAuthInfo) === null || _b === void 0 ? void 0 : _b.info) === null || _c === void 0 ? void 0 : _c.avatar; });
    var isAuthorized = useSelector(function (state) { return state.auth.isAuth; });
    var userAvatarFull = "".concat(NEW_URL).concat(userAvatar);
    var dispatch = useAppDispatch();
    var location = useLocation();
    var footerIcons = [
        { name: "Главная", img: Footer_Main_icon, link: "/news/" },
        { name: "События", img: Footer_Events_icon, link: "events/" },
        { name: "Вакансии", img: Footer_Forum_icon, link: "/vacancy/" },
        { name: "Компании", img: Footer_Company_icon, link: "/companies_page/" },
    ];
    return (_jsx("div", __assign({ className: style.footer }, { children: _jsxs("div", __assign({ className: style.footer_container }, { children: [footerIcons.map(function (item, index) { return (_jsxs(NavLink, __assign({ className: function (_a) {
                        var isActive = _a.isActive;
                        return (isActive || (location.pathname === "/" && index === 0) ? style.nav_active : style.nav);
                    }, to: item.link }, { children: [_jsx("img", { src: item.img, alt: "footer icon", height: "18px" }), _jsx("span", __assign({ className: style.iconTitle }, { children: item.name }))] }), index)); }), isAuthorized ? (_jsxs(NavLink, __assign({ className: function (_a) {
                        var isActive = _a.isActive;
                        return (isActive ? style.nav_profile_active : style.nav_profile);
                    }, to: checkWhoIsWho(userRole, isSuperUser) }, { children: [_jsx("img", { className: style.avatar, src: userAvatarFull, alt: "avatar", height: "18px" }), _jsx("span", __assign({ className: style.nav_name }, { children: "\u041F\u0440\u043E\u0444\u0438\u043B\u044C" }))] }))) : (_jsxs(NavLink, __assign({ onClick: function () { return dispatch(setAuthModalOpenStatus({ isOpen: true })); }, className: style.nav, to: checkWhoIsWho(userRole, isSuperUser) }, { children: [_jsx("img", { src: Footer_Person_icon, alt: "avatar", height: "18px" }), _jsx("span", __assign({ className: style.nav_name }, { children: "\u041F\u0440\u043E\u0444\u0438\u043B\u044C" }))] })))] })) })));
}
