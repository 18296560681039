// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gsURzMCmvOop_l9JK4YA {
  cursor: pointer;
  border: none;
  width: 300px;
  height: 40px;
  min-height: 40px;
  background: rgba(20, 130, 197, 0.6);
  border-radius: 8px;
  color: var(--main-white-color);
  font-size: 14px;
  font-weight: 700;
}

.iHw12GQyiWhsn18PoPKf {
  width: 300px;
  min-height: 40px;
  border-radius: 8px;
  opacity: 0.35;
  margin-top: 6px;
}

@media (max-width: 480px) {

  .gsURzMCmvOop_l9JK4YA,
  .iHw12GQyiWhsn18PoPKf {
    min-height: 40px;
    height: 40px;
    width: 100%;

    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-height: 700px) {

  .gsURzMCmvOop_l9JK4YA,
  .iHw12GQyiWhsn18PoPKf {
    width: 300px;
    height: 40px;
    background: rgba(20, 130, 197, 0.6);
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Helpers/Forms/SubmitButton/SubmitButton.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,mCAAmC;EACnC,kBAAkB;EAClB,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;;AAEA;;EAEE;;IAEE,gBAAgB;IAChB,YAAY;IACZ,WAAW;;IAEX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;AACF;;AAEA;;EAEE;;IAEE,YAAY;IACZ,YAAY;IACZ,mCAAmC;IACnC,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".button_submitted {\n  cursor: pointer;\n  border: none;\n  width: 300px;\n  height: 40px;\n  min-height: 40px;\n  background: rgba(20, 130, 197, 0.6);\n  border-radius: 8px;\n  color: var(--main-white-color);\n  font-size: 14px;\n  font-weight: 700;\n}\n\n.button_unSubmitted {\n  width: 300px;\n  min-height: 40px;\n  border-radius: 8px;\n  opacity: 0.35;\n  margin-top: 6px;\n}\n\n@media (max-width: 480px) {\n\n  .button_submitted,\n  .button_unSubmitted {\n    min-height: 40px;\n    height: 40px;\n    width: 100%;\n\n    width: 300px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n}\n\n@media (max-height: 700px) {\n\n  .button_submitted,\n  .button_unSubmitted {\n    width: 300px;\n    height: 40px;\n    background: rgba(20, 130, 197, 0.6);\n    border-radius: 8px;\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 130%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_submitted": `gsURzMCmvOop_l9JK4YA`,
	"button_unSubmitted": `iHw12GQyiWhsn18PoPKf`
};
export default ___CSS_LOADER_EXPORT___;
