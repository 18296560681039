var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Menu } from "../Menu/Menu";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { SubMenu } from "../SubMenu/SubMenu";
import { useMediaQuery } from "react-responsive";
import style from "./Layout.module.css";
import style_layout from "../../styles/layout/grid.module.css";
import { ModalContainer } from "../Modal/ModalContainer/ModalContainer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { HeaderForMobile } from "../Header/HeaderForMobile/HeaderForMobile";
import { setIsCoockesAgrementOpenStatus } from "../../redux/modalReducer";
import ModalCoockieAgreement from "../Modal/modalCoockieAgreement/modalCoockieAgreement";
import GetCookie from "../../hooks/cookies/getCookie";
import { FollowUsBlock } from "../elements/cards/followUsBlock/FollowUsBlock";
import { ButtonUp } from "../Button/ButtonUp/ButtonUp";
import { logoutUser, logoutUserAndClearCookies } from "../../redux/authReducer";
var Layout = function (props) {
    var _a, _b;
    var isMobile = useMediaQuery({
        query: "(max-width: 480px)",
    });
    var isPlanshet = useMediaQuery({
        query: "(max-width: 1240px)",
    });
    var isMenuNotDisplayed = useMediaQuery({
        query: "(max-width: 960px)",
    });
    var isLowHeight = useMediaQuery({
        query: "(max-height: 1160px)",
    });
    var dispatch = useAppDispatch();
    var location = useLocation();
    var isModalOpen = useSelector(function (state) { return state.modal.isActive; });
    var isBanned = useSelector(function (state) { return state.auth.userAuthInfo.banned; });
    var isAuth = useSelector(function (state) { return state.auth.isAuth; });
    var isCoockiesAgreementOpen = useSelector(function (state) { return state.modal.isCoockiesAgreementOpen; });
    var isVisitSite = GetCookie("visit") === "true";
    var isNotFoundPageOpen = useSelector(function (state) { return state.commonReducer.isNotFoundPageOpen; });
    var isNeedLogoutAll = GetCookie("needLogoutAllAndClearCookie") === "true";
    useEffect(function () {
        if (isNeedLogoutAll) {
            dispatch(logoutUserAndClearCookies());
        }
    }, [GetCookie("needLogoutAllAndClearCookie")]);
    useEffect(function () {
        if (isBanned) {
            dispatch(logoutUser());
        }
    }, [isBanned]);
    useEffect(function () {
        if (isVisitSite) {
            return;
        }
        else {
            setTimeout(function () {
                dispatch(setIsCoockesAgrementOpenStatus({ isOpen: true }));
            }, 150000); // 2.5минуты
        }
    }, [isVisitSite]);
    //это  для того чтобы убирать скролл на сайте, когда открыта модалка
    useEffect(function () {
        if (isModalOpen) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "auto";
        }
    }, [isModalOpen]);
    function isNeedDisplayMenu() {
        if (isPlanshet && (location.pathname.includes("profile") || location.pathname.includes("Profile") || location.pathname.includes("admin"))) {
            return false;
        }
        else if (location.pathname === "/test_moder/" || isNotFoundPageOpen || isMenuNotDisplayed) {
            return false;
        }
        else {
            return true;
        }
    }
    return (_jsxs("div", __assign({ className: style_layout.layout }, { children: [_jsx(ModalContainer, {}), isMenuNotDisplayed ? _jsx(HeaderForMobile, {}) : _jsx(Header, {}), _jsxs("div", __assign({ className: style.layout_box }, { children: [_jsxs("div", __assign({ className: style.layout_WrapperMenuAndMainContent }, { children: [isNeedDisplayMenu() && (_jsx("div", __assign({ className: style.stickyMenuWrapper }, { children: _jsxs("div", __assign({ className: style_layout.col_menu_section }, { children: [_jsx(Menu, { menu: (_a = props.menu) === null || _a === void 0 ? void 0 : _a[0] }), _jsxs("div", __assign({ style: { transform: "translateY(45vh)" } }, { children: [_jsx(SubMenu, { subMenu: (_b = props.menu) === null || _b === void 0 ? void 0 : _b[1] }), _jsx(FollowUsBlock, {})] }))] })) }))), _jsx(Outlet, {}), isCoockiesAgreementOpen && _jsx(ModalCoockieAgreement, { isCoockiesAgreementOpen: isCoockiesAgreementOpen })] })), isPlanshet ? _jsx(ButtonUp, {}) : null] })), isMobile && _jsx(Footer, {})] })));
};
export { Layout };
