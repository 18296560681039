// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NGqZlxuJDGvsTP59rlA6 {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-left: 10px;
}

.NGqZlxuJDGvsTP59rlA6 span {
    font-style: normal;
    font-weight: 480;
    font-size: 90%;
    /* 16px */
    line-height: 32px;
    letter-spacing: 0.1px;
    color: var(--main-black-of-black-color);
    margin-left: 8px;
}

.b2_l3Qvm4kM_eWiPMHp3 {
    width: 10px;
    height: 5px;
    margin-left: 4px;
}

.Pjq2PRBf7_jB0slyk_Rv {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 93.4%;
    background-color: transparent;
    z-index: 998;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    grid-template-columns: 1fr;
    justify-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/helpers/loginBlock.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,SAAS;IACT,iBAAiB;IACjB,qBAAqB;IACrB,uCAAuC;IACvC,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,WAAW;IACX,aAAa;IACb,6BAA6B;IAC7B,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,qBAAqB;IACrB,0BAA0B;IAC1B,qBAAqB;AACzB","sourcesContent":[".login {\n    display: flex;\n    cursor: pointer;\n    align-items: center;\n    margin-left: 10px;\n}\n\n.login span {\n    font-style: normal;\n    font-weight: 480;\n    font-size: 90%;\n    /* 16px */\n    line-height: 32px;\n    letter-spacing: 0.1px;\n    color: var(--main-black-of-black-color);\n    margin-left: 8px;\n}\n\n.chevronIconStyle {\n    width: 10px;\n    height: 5px;\n    margin-left: 4px;\n}\n\n.logoutLittleMenuWrapper {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 93.4%;\n    background-color: transparent;\n    z-index: 998;\n    overflow: hidden;\n    display: flex;\n    justify-content: flex-end;\n    align-content: center;\n    grid-template-columns: 1fr;\n    justify-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `NGqZlxuJDGvsTP59rlA6`,
	"chevronIconStyle": `b2_l3Qvm4kM_eWiPMHp3`,
	"logoutLittleMenuWrapper": `Pjq2PRBf7_jB0slyk_Rv`
};
export default ___CSS_LOADER_EXPORT___;
