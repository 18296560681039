// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FYKDIU9oK00g2h_iWJsR {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--modal-blur-wrapper);
  backdrop-filter: blur(3px);
  z-index: 998;
  overflow: hidden;
  display: flex;
  align-content: center;
  grid-template-columns: 1fr;
  justify-items: center;
}

@media (max-width: 480px) {
  .FYKDIU9oK00g2h_iWJsR {
    /* position: relative; */
    /* position: absolute; */
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Helpers/ModalBlurWrapper/ModalBlurWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,2CAA2C;EAC3C,0BAA0B;EAC1B,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,qBAAqB;EACrB,0BAA0B;EAC1B,qBAAqB;AACvB;;AAEA;EACE;IACE,wBAAwB;IACxB,wBAAwB;EAC1B;AACF","sourcesContent":[".modalBlurWrapper {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: var(--modal-blur-wrapper);\n  backdrop-filter: blur(3px);\n  z-index: 998;\n  overflow: hidden;\n  display: flex;\n  align-content: center;\n  grid-template-columns: 1fr;\n  justify-items: center;\n}\n\n@media (max-width: 480px) {\n  .modalBlurWrapper {\n    /* position: relative; */\n    /* position: absolute; */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBlurWrapper": `FYKDIU9oK00g2h_iWJsR`
};
export default ___CSS_LOADER_EXPORT___;
