var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import style from "./InputForAuthAndReg.module.css";
import help_icon from "../../../../assets/img/help_popup_icon.svg";
import { useMediaQuery } from "react-responsive";
import VisibleOffIcon from "../../../../assets/img/VisibleOff.png";
import VisibleOnIcon from "../../../../assets/img/VisibleOn.png";
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
export var InputForAuthAndReg = function (_a) {
    var handleBlur = _a.handleBlur, handleChange = _a.handleChange, isError = _a.isError, value = _a.value, placeholder = _a.placeholder, name = _a.name, type = _a.type, isNeedHelpPopup = _a.isNeedHelpPopup, popupText = _a.popupText;
    var isMobile = useMediaQuery({
        query: "(max-width: 480px)",
    });
    var _b = useState(false), isVisible = _b[0], seVisible = _b[1];
    return (_jsxs("div", __assign({ className: style.input_block }, { children: [_jsx("div", __assign({ className: style.input_block_wrapper }, { children: _jsxs(FormControl, { children: [_jsx(InputLabel, __assign({ sx: { fontSize: "14px", display: "flex", alignItems: "center", lineHeight: "13.8px", color: "#828282" } }, { children: placeholder })), _jsx(OutlinedInput, { type: type === "password" ? (isVisible ? "text" : "password") : "text", name: name, sx: { height: "40px", width: "300px", color: "#828282", fontSize: "13px" }, fullWidth: true, value: value, onChange: function (event) {
                                handleChange(event);
                            }, onBlur: function (event) {
                                handleBlur(event);
                            }, error: isError, endAdornment: _jsx(InputAdornment, __assign({ position: "end", sx: { color: "#828282" } }, { children: type === "password" ? (_jsx("img", { className: style.visibleIcon, src: isVisible ? VisibleOnIcon : VisibleOffIcon, onClick: function () { return seVisible(function (prevState) { return !prevState; }); } })) : null })), label: placeholder })] }) })), !isMobile && isNeedHelpPopup && (_jsxs(_Fragment, { children: [_jsx("img", { className: isError ? style.help_popup_icon_error : style.help_popup_icon, src: help_icon, alt: "icon help" }), _jsx("div", __assign({ className: style.help_popup }, { children: _jsx("p", { children: popupText }) }))] }))] })));
};
